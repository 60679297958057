@import 'libs/shared/src/lib/utils/utils';
@import 'libs/shared/src/lib/utils/fonts';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Artegra', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Artegra-Bold';
}

.primary-color {
  color: #6e54fc;
}

.accent-color {
  color: #000000;
}

.warning-color {
  color: #ffcd1c;
}

.error-color {
  color: #fc4e69;
}

.success-color {
  color: #389e0d;
}

p {
  b,
  strong {
    font-family: 'Artegra-Bold';
  }
  a {
    font-family: 'Artegra-Semibold';
  }
}

.defaultModal .ant-modal-content {
  min-height: 100vh;
}
