@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url('../../../src/lib/assets/fonts/inter/Inter.ttf');
}

@font-face {
  font-family: 'Artegra';
  src: url('../../../../shared/src/lib/assets/fonts/artegra/Artegra_Sans-500-Medium.otf');
}

@font-face {
  font-family: 'Artega-Sans';
  src: url('../../../../shared/src/lib/assets/fonts/artegra/Artegra_Sans-600-SemiBold.otf');
}

@font-face {
  font-family: 'Artegra-Medium';
  src: url('../../../../shared/src/lib/assets/fonts/artegra/Artegra_Sans-500-Medium.otf');
}

@font-face {
  font-family: 'Artegra-Bold';
  src: url('../../../../shared/src/lib/assets/fonts/artegra/Artegra_Sans-900-Black.otf');
}

@font-face {
  font-family: 'Artegra-Semibold';
  src: url('../../../../shared/src/lib/assets/fonts/artegra/Artegra_Sans-700-Bold.otf');
}
